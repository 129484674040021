import React from "react"
import { styled } from "@linaria/react"
import { Modal } from "react-bootstrap"
import { number, bool, func, string } from "prop-types"
import { pick } from "lodash"

import { BREAKPOINTS, FONT_SIZES } from "src/styles/sizes"

import DocumentPreview from "./DocumentPreview"

const PreviewModal = ({ className, isOpened, close, name, ...props }) => {
  return (
    <Modal size="xl" show={isOpened} onHide={close} className={className}>
      <Modal.Header closeButton>
        <Modal.Title>{name}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <DocumentPreview
          isOpened={isOpened}
          {...pick(props, ["id", "pspdfkitId", "jwt", "users"])}
        />
      </Modal.Body>
    </Modal>
  )
}

PreviewModal.propTypes = {
  id: number.isRequired,
  isOpened: bool,
  close: func.isRequired,
  name: string.isRequired
}

export default styled(PreviewModal)`
  .document-preview {
    height: 100%;
  }

  .modal-header {
    flex-grow: 0;
  }

  .modal-content {
    height: 100%;
  }

  @media (min-width: ${BREAKPOINTS.xSmall}) {
    .modal-dialog {
      width: 90%;
      max-width: 90%;
    }
  }

  @media (min-width: ${BREAKPOINTS.large}) {
    .modal-dialog {
      max-width: 80vw;
    }
  }

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    .modal-title {
      font-size: ${FONT_SIZES.medium};
    }

    .modal-dialog {
      height: calc(100% - 1rem);

      .modal-content {
        height: calc(100%);

        .modal-body {
          height: calc(100%);
        }
      }
    }
  }
`
