import { styled } from "@linaria/react"

import UnstyledButton from "src/components/UnstyledButton"
import Icon from "src/styles/components/Icon"

import { BASE_TEXT_COLOR, TRANSITION_PROPERTIES } from "../styles"
import FocusableItemIconBase from "../FocusableItemIcon"

export const BORDER_RADIUS = "8px"
export const BORDER_COLOR = "#e9e9ea"
export const BORDER = `1px solid ${BORDER_COLOR}`
export const PADDING_X = "16px"
export const PADDING = `10px ${PADDING_X}`
export const LABEL_FONT_SIZE = "12px"
export const LABEL_FONT_WEIGHT = "500"
export const LABEL_LINE_HEIGHT = "15px"

export const AreaOfFocusContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 14px;
  line-height: 20px;
  color: ${BASE_TEXT_COLOR};
  position: relative;
  margin-bottom: 12px;
  height: 65px;
  opacity: 1;
  transition:
    height ${TRANSITION_PROPERTIES},
    opacity ${TRANSITION_PROPERTIES};

  &.hidden {
    height: 0;
    margin-bottom: 0;
    opacity: 0;
  }
`

export const Label = styled.label`
  font-size: ${LABEL_FONT_SIZE};
  font-weight: ${LABEL_FONT_WEIGHT};
  line-height: ${LABEL_LINE_HEIGHT};
  margin-bottom: 0;
`

export const DropdownButton = styled(UnstyledButton)`
  padding: ${PADDING};
  border: ${BORDER};
  border-radius: ${BORDER_RADIUS};
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.disabled {
    cursor: not-allowed;
    background-color: #fbfbfb;
  }
`

export const FocusableItemIcon = styled(FocusableItemIconBase)``

export const DropdownTitle = styled.span`
  text-align: left;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  min-height: 20px;
  display: flex;
  align-items: center;

  // Nesting to increase specificity
  & ${FocusableItemIcon} {
    color: #a7a7ab;
  }
`

export const DropdownTitleLabel = styled.span`
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`

export const DropdownIcon = styled(Icon)`
  font-weight: bold;
  position: relative;
  top: 1px;

  &.opened {
    transform: rotate(180deg);
    top: 0;
    left: 1px;
  }
`
