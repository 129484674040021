import { styled } from "@linaria/react"

import { FONT_SIZES } from "src/styles/sizes"
import { TEXT_COLOR } from "src/features/SupportingMaterialsVault/constants"

export const Container = styled.div`
  color: ${TEXT_COLOR};

  .dropdown-menu {
    width: 210px;
  }

  .dropdown-toggle {
    font-size: ${FONT_SIZES.medium};
    cursor: pointer;
  }

  .icon-archive,
  .icon-archive-unpack {
    padding-right: 10px;
  }
`
