import { styled } from "@linaria/react"

import LinkButton from "src/styles/components/Button/Link"
import { BRAND_COLOR } from "src/features/SupportingMaterialsVault/constants"

export const Button = styled(LinkButton)`
  color: ${BRAND_COLOR};
  font-size: 14px;

  &:hover,
  &:focus {
    color: ${BRAND_COLOR};
  }
`
